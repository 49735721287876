export const TYPE_RESTORE_USER_PASSWORD = 'RESTORE_USER_PASSWORD';
export const restoreUserPassword = (email: string) =>
  ({
    type: TYPE_RESTORE_USER_PASSWORD,
    payload: { email },
  }) as const;
export type RestoreUserPasswordAction = ReturnType<typeof restoreUserPassword>;

export const TYPE_INVITE_USERS = 'INVITE_USERS';
export const inviteUsers = (
  data: {
    clientsId: number;
    rolesId: number;
    emails: string[];
    skipInvitationEmail: boolean;
  },
  successCallback?: () => void
) =>
  ({
    type: TYPE_INVITE_USERS,
    payload: { data, successCallback },
  }) as const;
export type InviteUsersAction = ReturnType<typeof inviteUsers>;

export const TYPE_RESEND_WELCOME_EMAIL = 'RESEND_WELCOME_EMAIL';
export const resendWelcomeEmail = (usersId: number) =>
  ({
    type: TYPE_RESEND_WELCOME_EMAIL,
    payload: { usersId },
  }) as const;
export type ResendWelcomeEmailAction = ReturnType<typeof resendWelcomeEmail>;

export type UserActions = RestoreUserPasswordAction | InviteUsersAction | ResendWelcomeEmailAction;
