import { RouteDef } from 'app/hooks/useRenderRoutes';
import safeLazy from 'core/functions/safeLazy';

import { personnelEntities } from './module';

export const personnelRoutes: RouteDef[] = [
  // Clients
  {
    path: personnelEntities.client.urls().list,
    component: safeLazy(() => import('./pages/ClientsPage')),
    layout: 'side',
  },
  {
    path: personnelEntities.client.urls().create,
    component: safeLazy(() => import('./pages/ClientDetailPage')),
    layout: 'side',
    mode: 'create',
    exact: false,
  },
  {
    path: personnelEntities.client.urls().clone(':id'),
    component: safeLazy(() => import('./pages/ClientDetailPage')),
    layout: 'side',
    mode: 'clone',
    exact: false,
  },
  {
    path: personnelEntities.client.urls().detail(':id'),
    component: safeLazy(() => import('./pages/ClientDetailPage')),
    layout: 'side',
    mode: 'edit',
    exact: false,
  },
  // Users
  {
    path: personnelEntities.user.urls().list,
    component: safeLazy(() => import('./pages/UsersPage')),
    layout: 'side',
  },
  {
    path: personnelEntities.user.urls().create,
    component: safeLazy(() => import('./pages/UsersDetailPage')),
    layout: 'side',
    mode: 'create',
    exact: false,
  },
  {
    path: personnelEntities.user.urls().clone(':id'),
    component: safeLazy(() => import('./pages/UsersDetailPage')),
    layout: 'side',
    mode: 'clone',
    exact: false,
  },
  {
    path: personnelEntities.user.urls().detail(':id'),
    component: safeLazy(() => import('./pages/UsersDetailPage')),
    layout: 'side',
    mode: 'edit',
    exact: false,
  },
];
