import { FC } from 'react';

import MaterialIcon from 'core/components/MaterialIcon';

import { entities } from './entity';
import { RolesId } from './models/Role';
import { urls } from './urls';

export interface NavSubItem {
  key: string;
  text: string;
  to: string;
  notifications?: number;
  /** optional parameter - if its set, we are looking for one of the specified client options */
  clientOption?: string[];
  /**
   * Optional custom permission - if not set key is used instead
   */
  permission?: string;
  /**
   * Optional roles that will be the only one with this item
   */
  restrictedToRoles?: RolesId[];
  /**
   * Is the optional search available for this item
   */
  search?: boolean;
}

export interface NavGroup {
  key: string;
  title: string;
  img: FC;
  items?: NavSubItem[];
  to?: string;
  /** optional parameter - if its set, we are looking for one of the specified client options */
  clientOption?: string[];
  /**
   * Optional custom permission - if not set ${key}:find is used instead
   */
  permission?: string;
  /**
   * Optional roles that will be the only one with this item
   */
  restrictedToRoles?: RolesId[];
  /**
   * Is the optional search available for this item
   */
  search?: boolean;
}

// TODO: Properties should come from the entities, blocked by https://cannypack.atlassian.net/browse/MODFE-1974
const getNavGroups = (t: (translateText: string) => string): NavGroup[] => [
  {
    key: 'dashboard',
    title: t('Dashboard'),
    img: () => <MaterialIcon icon="home" />,
    permission: 'dashboard:view',
    items: [],
    to: '/',
  },
  {
    key: 'sales',
    title: t('Sales Funnel'),
    img: () => <MaterialIcon icon="filter_alt" />,
    restrictedToRoles: [RolesId.SUPER_ADMINISTRATOR, RolesId.ADMINISTRATOR, RolesId.USER],
    items: [
      { key: 'contacts', text: t('Contacts'), to: entities.contact.urls().list, search: true },
      {
        key: 'lead_management',
        text: t('Lead Management'),
        to: entities.contact.urls().leadManagement,
        search: true,
        permission: 'contacts',
      },
      {
        key: 'pipeline',
        permission: 'projects:find',
        text: t('Pipeline'),
        to: entities.project.urls().pipeline,
        search: true,
      },
      { key: 'companies', text: t('Companies'), to: entities.company.urls().list, search: true },
    ],
  },
  {
    key: 'projects',
    title: t('Projects'),
    img: () => <MaterialIcon icon="local_offer" />,
    permission: 'projects:find',
    restrictedToRoles: [RolesId.SUPER_ADMINISTRATOR, RolesId.ADMINISTRATOR, RolesId.USER],
    items: [],
    to: entities.project.urls().list,
    search: true,
  },
  {
    key: 'timesheets',
    title: t('Timesheets'),
    img: () => <MaterialIcon icon="timelapse" />,
    permission: 'timesheets:find',
    items: [],
    to: entities.timesheet.urls().list,
    search: true,
  },
  {
    key: 'allocations',
    permission: 'allocations:find',
    title: t('Allocations'),
    img: () => <MaterialIcon icon="timeline1" />,
    to: urls.allocations,
    search: true,
  },
  {
    key: 'forecasts',
    permission: 'forecasts:find',
    title: t('Forecasts'),
    img: () => <MaterialIcon icon="show_chart" />,
    to: urls.forecasts,
  },
  {
    key: 'finances',
    permission: 'costs:find',
    title: t('Finances'),
    img: () => <MaterialIcon icon="attach_money" />,
    items: [
      { key: 'costs', text: t('Costs'), to: entities.cost.urls().list, search: true },
      {
        key: 'businessPlan',
        text: t('Business Plan'),
        permission: 'costs',
        to: entities.businessPlan.urls().overview(new Date().getFullYear()),
      },
    ],
  },
  {
    key: 'team',
    permission: 'users:find',
    title: t('Team'),
    img: () => <MaterialIcon icon="group" />,
    to: entities.user.urls().list,
    search: true,
    restrictedToRoles: [RolesId.SUPER_ADMINISTRATOR, RolesId.ADMINISTRATOR, RolesId.USER],
  },
  {
    key: 'lists',
    title: t('Lists'),
    permission: 'costs:find',
    img: () => <MaterialIcon icon="list" />,
    restrictedToRoles: [RolesId.SUPER_ADMINISTRATOR, RolesId.ADMINISTRATOR, RolesId.USER],
    items: [
      {
        key: 'cost-clusters',
        text: t('Cost Clusters'),
        to: entities.costCluster.urls().list,
        search: false,
        permission: 'costs:find',
      },
      {
        key: 'cost-categories',
        text: t('Cost Categories'),
        to: entities.costCategory.urls().list,
        search: false,
        permission: 'costs:find',
      },
    ],
  },
  {
    key: 'files',
    title: t('Attachments'),
    img: () => <MaterialIcon icon="attach_file" />,
    items: [],
    to: urls.attachment,
    clientOption: ['enableAttachments'], // TODO: rename setting
    search: true,
  },
  {
    key: 'system',
    title: t('System'),
    img: () => <MaterialIcon icon="stars" />,
    restrictedToRoles: [RolesId.SUPER_ADMINISTRATOR],
    items: [
      { key: 'clients', text: t('Clients'), to: entities.client.urls().list, search: true },
      {
        key: 'admin/permissions',
        text: t('Permissions'),
        to: urls.permissions,
      },
    ],
  },
];

export default getNavGroups;
