import { ClientFormData } from 'personnel/pages/ClientDetailPage/ClientForm/useSchema';

export const TYPE_EDIT_CLIENT = 'EDIT_CLIENT';
export const editClient = (
  id: number,
  data: ClientFormData,
  prevEntity: ClientFormData,
  endpoint: string
) =>
  ({
    type: TYPE_EDIT_CLIENT,
    payload: {
      id,
      data,
      prevEntity,
      endpoint,
    },
  }) as const;

export type EditClientAction = ReturnType<typeof editClient>;

export type ClientActions = EditClientAction;
