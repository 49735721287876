import { GridApi, RowClickedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { copyToClipboard } from 'core/actions';
import AgGridPaperWrapper from 'core/components/AgGridPaperWrapper';
import Page from 'core/components/Page';
import 'core/containers/DataGrid/styles.scss';

import { IconCellRenderer } from '../DataGrid/FastCellRenderers';

import { formatParams } from './helpers';
import useColumnDefs from './useColumnDefs';
import useGroupPermissions, { ExtendedPermission, PermissionGroup } from './useGroupPermissions';

const PermissionsPage: FC<{}> = () => {
  const { t } = useTranslation();
  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);
  const groupPermissions = useGroupPermissions();
  const columnDefs = useColumnDefs();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!gridApi) return;

    gridApi.setColumnDefs(columnDefs);
    gridApi.sizeColumnsToFit();
  }, [gridApi, columnDefs]);

  const onRowClicked = ({ data }: RowClickedEvent<ExtendedPermission | PermissionGroup>) => {
    if (data && 'controller' in data && 'action' in data) {
      const devPermission = `${data.controller}:${data.action}${
        data?.params ? `[${formatParams(data?.params, false)}]` : ''
      }`;

      dispatch(copyToClipboard(devPermission));
    }
  };

  return (
    <Page title={t('Permissions')}>
      <AgGridPaperWrapper fullHeight>
        <AgGridReact
          defaultColDef={{ sortable: false }}
          onRowClicked={onRowClicked}
          columnDefs={columnDefs}
          suppressHorizontalScroll={false}
          rowData={groupPermissions}
          components={{
            iconCellRenderer: IconCellRenderer,
          }}
          onGridReady={({ api }) => {
            api.sizeColumnsToFit();
            setGridApi(api);
          }}
        />
      </AgGridPaperWrapper>
    </Page>
  );
};

export default PermissionsPage;
