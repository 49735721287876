import { useMemo } from 'react';

import useResource from 'core/hooks/useResource';
import { Permission } from 'core/models/CoreProfile';

export type ExtendedPermission = Permission & { rolesId: string[]; permissionKey: string };
export type PermissionGroup = { title: string; group: number };

type ProcessedPermission = {
  [permissionController: string]: {
    [permissionKeyWithParams: string]: ExtendedPermission;
  };
};

const useGroupPermissions = () => {
  const { data: permissions } = useResource<{ [rolesId: string]: Permission[] }>(
    'admin/permissions'
  );

  const groupPermissions: (ExtendedPermission | PermissionGroup)[] | undefined = useMemo(() => {
    if (!permissions) return [];

    // We have from API all roles permissions {rolesId: Permission[]}, make it ready for datagrid to display
    const unsortedRolesPermissions = Object.keys(permissions).reduce(
      (acc: ProcessedPermission, rolesId: any) => {
        permissions[rolesId].forEach((rolePermission: Permission) => {
          const permKey = `${rolePermission.controller}:${rolePermission.action}:${
            (rolePermission.params && JSON.stringify(rolePermission.params)) || ''
          }`;

          if (!(rolePermission.controller in acc)) {
            acc[rolePermission.controller] = {};
          }

          if (permKey in acc[rolePermission.controller]) {
            // permission controller already exists, add only rolesId
            acc[rolePermission.controller][permKey].rolesId.push(rolesId);
          } else {
            // add new controller permissions
            acc[rolePermission.controller][permKey] = {
              ...rolePermission,
              rolesId: [rolesId],
              permissionKey: permKey,
            };
          }
        });

        return acc;
      },
      {}
    );

    const sortedPermissions = Object.keys(unsortedRolesPermissions)
      .sort()
      .reduce((acc: ProcessedPermission, permissionController: string) => {
        const sortedSubPermissions = Object.keys(unsortedRolesPermissions[permissionController])
          .sort()
          .reduce(
            (
              subAcc: { [permissionKeyWithParams: string]: ExtendedPermission },
              permissionKey: string
            ) => {
              subAcc[permissionKey] = unsortedRolesPermissions[permissionController][permissionKey];
              return subAcc;
            },
            {}
          );

        acc[permissionController] = sortedSubPermissions;
        return acc;
      }, {});

    return Object.keys(sortedPermissions).reduce(
      (acc: (ExtendedPermission | PermissionGroup)[], permController: string) => {
        // Add name of the group
        acc.push({
          title: permController,
          group: Object.keys(sortedPermissions[permController]).length,
        });

        // Add group permissions
        acc.push(
          ...Object.keys(sortedPermissions[permController]).map((it) => ({
            ...sortedPermissions[permController][it],
            id: it,
          }))
        );
        return acc;
      },
      []
    );
  }, [permissions]);

  return groupPermissions;
};

export default useGroupPermissions;
