import { useEffect, useRef } from 'react';

import { t } from 'core/i18n';
import { ApiQuery, ApiQueryStatus, CoreState } from 'core/reducer';

import useAppSelector from '../useAppSelector';

import displayApiToast, { closeToast, closeToasts, ToastType } from './displayApiToast';

export function useApiCallToasts(): void {
  const queries = useAppSelector((s) => s.core.apiQueries);

  const statuses = useRef(statusesToMap(queries));

  // track status change and display proper toast
  useEffect(() => {
    const current = statusesToMap(queries);
    const prev = statuses.current;

    current.forEach((status, key) => {
      const query = queries[key];

      // skip ot-changed status
      if (status !== prev.get(key)) {
        // process success
        if (status === ApiQueryStatus.SUCCESS) {
          if (
            ['PATCH', 'POST', 'DELETE'].includes(query.options.method || 'GET') &&
            query.options.successMessage !== null
          ) {
            displayApiToast(...getDefaultSuccessMsg(key, query));
          }
          // process errors
        } else if (status === ApiQueryStatus.ERROR && query.options.showError !== false) {
          const errorMessage =
            query.response?.data?.message ||
            query.response?.statusText ||
            (query.error instanceof Error ? query.error?.message : query.error) ||
            t('Unknown error');

          displayApiToast(
            key,
            'error',
            query.options.errorMessage ||
              t('Communication Failed: {{errorMessage}}', { errorMessage })
          );
        } else if (status === ApiQueryStatus.LOADING) {
          if (
            (!query.options.method || query.options.method !== 'GET') &&
            query.options.loadingMessage !== null
          ) {
            displayApiToast(key, 'loading', query.options.loadingMessage || t('Processing...'));
          }
        } else {
          closeToast(key);
        }
      }
    });

    statuses.current = current;
  }, [queries]);

  // Clear all pending toasts on unmount
  useEffect(() => {
    return () => {
      closeToasts();
    };
  }, []);
}

function statusesToMap(queries: CoreState['apiQueries']): Map<string, ApiQueryStatus> {
  const map = new Map();

  Object.keys(queries).forEach((key) => {
    map.set(key, queries[key].status);
  });

  return map;
}

function getDefaultSuccessMsg(
  key: string,
  query: ApiQuery<any>
): [string, undefined | ToastType, string] {
  const isBulk = (query.options.url || key).includes('/bulk');
  const count = query.data?.updated || query.data?.deleted || 0;

  if (query.options.successMessage) {
    return [key, 'success', query.options.successMessage as string];
  }

  if (query.options.successMessage === false) {
    return [key, undefined, ''];
  }

  switch (query.options.method) {
    case 'PATCH':
      if (isBulk) {
        if (!count) {
          return [key, 'warning', t('Nothing was changed')];
        }
        return [key, 'success', t('Affected records: {{count}}', { count })];
      }
      return [key, 'success', t('Data were updated successfully')];
    case 'DELETE':
      // we can add counts for deletion 2
      return [key, 'success', t('Successfully deleted')];
    default:
      return [key, undefined, ''];
  }
}
