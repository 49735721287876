import { definition, EntityMeta, getStandardApi, getStandardUrls, T } from 'core/module';

import { ProjectCreate, ProjectDetail, ProjectGrid } from './models/Project';

export const projectsEntities = {
  project: {
    ...definition<ProjectGrid, ProjectDetail, ProjectCreate>('projects', {
      name: (t: T) => t('Project'),
      listName: (t: T) => t('Projects'),
    }),
    urls: (_?: string) => ({
      ...getStandardUrls('projects'),
      pipeline: '/pipeline',
    }),
    api: (type?: string) => ({
      ...getStandardApi('projects'),
      list: type ? `/projects?type=${type}` : '/projects',
      forecasts: '/projects/forecasts',
      detailForecasts: (id: string | number) => `/projects/${id}/forecasts`,
    }),
  },
} as const;

// REQUIRED !!!
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const verifyEntities: Record<keyof typeof projectsEntities, EntityMeta> = projectsEntities;

export type K = keyof typeof projectsEntities;
