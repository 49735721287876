import { RouteDef } from 'app/hooks/useRenderRoutes';
import safeLazy from 'core/functions/safeLazy';

export const forecastsUrls = { forecasts: '/forecasts' };

export const forecastRoutes: RouteDef[] = [
  {
    path: forecastsUrls.forecasts,
    component: safeLazy(() => import('./pages/ForecastsPage')),
    layout: 'side',
  },
];
