import User from 'app/models/User';
import { Client } from 'core/models/Client';
import { definition, EntityMeta, getStandardApi, getStandardUrls } from 'core/module';

type T = (m: string) => string;

export const personnelEntities = {
  user: {
    ...definition<User>('users', {
      name: (t: T) => t('User'),
      listName: (t: T) => t('Users'),
    }),
    urls: () => ({
      ...getStandardUrls('users'),
      allocations: (id: number | string, month?: string) =>
        `/users/${id}/edit/allocation?month=${month}`,
    }),
    api: () => ({
      ...getStandardApi('users'),
      forecasts: '/profile/forecasts',
      image: (id: number) => `/users/${id}/image`,
    }),
  },
  client: {
    ...definition<Client>('clients', {
      name: (t: T) => t('Client'),
      listName: (t: T) => t('Clients'),
    }),
    urls: () => ({ ...getStandardUrls('clients'), current: '/client' }),
    api: () => ({
      ...getStandardApi('clients'),
      image: (id?: number) => (id ? `/clients/${id}/image` : `/client/image`),
    }),
  },
} as const;

// REQUIRED !!!
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const verifyEntities: Record<keyof typeof personnelEntities, EntityMeta> = personnelEntities;

export type K = keyof typeof personnelEntities;
