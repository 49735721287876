import { FC, useEffect } from 'react';

const className = 'no-focus-outline';

function addClassAction() {
  document.documentElement.classList.add(className);
  document.removeEventListener('mousedown', addClassAction);
}

function removeClassAction(e: any) {
  // watch TAB key
  if (e.which === 9) {
    document.documentElement.classList.remove(className);
    document.removeEventListener('keyup', removeClassAction);
  }

  document.body.addEventListener('mousedown', addClassAction);
}

/**
 * https://jmperezperez.com/outline-focus-ring-a11y/
 */
const FocusWorkaround: FC<any> = () => {
  useEffect(() => {
    if (document.documentElement.classList.contains(className)) {
      document.body.addEventListener('keyup', removeClassAction);
      return () => {
        document.removeEventListener('keyup', removeClassAction);
      };
    }
  }, []);

  return null;
};

export default FocusWorkaround;
