import { put } from 'redux-saga/effects';

import { editEntity } from 'core/actions';

import { BusinessPlanEditSettingsAction } from './../actions/businessPlan';

export function* businessPlanEditSettingsSaga(action: BusinessPlanEditSettingsAction) {
  const { req, successCallback, prevData } = action.payload;

  yield put(editEntity({ ...req, successCallback }, prevData));
}
