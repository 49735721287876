import { definition, EntityMeta } from 'core/module';

import { TimeSheetDetail, TimeSheetGrid, TimeSheetCreate } from './models/TimeSheet';

export const timeSheetEntities = {
  timesheet: definition<TimeSheetGrid, TimeSheetDetail, TimeSheetCreate>('timesheets', {
    name: (t) => t('Timesheet'),
    listName: (t) => t('Timesheets'),
  }),
} as const;

// REQUIRED !!!
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const verifyEntities: Record<keyof typeof timeSheetEntities, EntityMeta> = timeSheetEntities;

export type K = keyof typeof timeSheetEntities;
