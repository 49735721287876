import User from 'app/models/User';
import { ApiCallSuccessAction, ApiCallErrorAction } from 'core/actions';
import { Role } from 'core/models/Role';

import { PersonnelActions } from './actions';

export interface PersonnelState {
  userDetail?: { loading: boolean; data: null | User };
  editingUserInProgress?: boolean;
  deleteUserInProgress?: boolean;
  userRoles?: Role[];
  inviteUsersPending?: boolean;
  dialogSaveInProgress?: boolean;
}

const initialState: PersonnelState = {};

export default function reducer(
  state = initialState,
  action:
    | PersonnelActions
    | ApiCallSuccessAction<'LOAD_ROLES_SUCCESS'>
    | ApiCallSuccessAction<'INVITE_USERS_SUCCESS'>
    | ApiCallErrorAction<'INVITE_USERS_ERROR'>
): PersonnelState {
  switch (action.type) {
    case 'INVITE_USERS':
      return {
        ...state,
        inviteUsersPending: true,
      };

    case 'INVITE_USERS_SUCCESS':
      return {
        ...state,
        inviteUsersPending: false,
      };

    case 'INVITE_USERS_ERROR':
      return {
        ...state,
        inviteUsersPending: false,
      };

    default:
      return state;
  }
}
