export const camelCaseToSpaces = (text: string) =>
  text.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());

export const formatPermissionName = (controller: string, action: string) =>
  `${action.charAt(0).toUpperCase() + action.slice(1)} ${camelCaseToSpaces(controller)}`;

export const formatParams = (params: { [key: string]: any }, humanReadable: boolean = true) => {
  const whiteSpace = humanReadable ? ` ` : '';
  return Object.keys(params)
    .map((it) => {
      if (Array.isArray(params[it])) {
        return `${it}:${whiteSpace}${params[it].join(`,${whiteSpace}`)}`;
      }
      return `${it}:${whiteSpace}${params[it]}`;
    })
    .join(humanReadable ? ` | ` : ',');
};
