import { RouteDef } from 'app/hooks/useRenderRoutes';
import safeLazy from 'core/functions/safeLazy';

import { financeEntities } from './module';

export const financeRoutes: RouteDef[] = [
  {
    path: financeEntities.cost.urls().list,
    component: safeLazy(() => import('./pages/CostListPage')),
    layout: 'side',
  },
  {
    path: financeEntities.cost.urls().detail(':id'),
    component: safeLazy(() => import('./pages/CostDetailPage')),
    layout: 'side',
    mode: 'edit',
    exact: false,
  },
  {
    path: financeEntities.cost.urls().create,
    component: safeLazy(() => import('./pages/CostDetailPage')),
    layout: 'side',
    mode: 'create',
    exact: false,
  },
  {
    path: financeEntities.cost.urls().clone(':id'),
    component: safeLazy(() => import('./pages/CostDetailPage')),
    layout: 'side',
    mode: 'clone',
    exact: false,
  },
  {
    path: financeEntities.businessPlan.urls().overview(':id'),
    component: safeLazy(() => import('./pages/BusinessPlanListPage')),
    layout: 'side',
    exact: false,
  },
];
