import styled from 'styled-components/macro';

export const SidebarRouteWrap = styled.div`
  display: flex;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;

  > :last-child {
    overflow-x: auto;
    overflow-y: auto;
  }

  @media print {
    height: auto;
  }
`;
