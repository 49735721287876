import { ComponentType, FC, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, RouteComponentProps, RouteProps } from 'react-router-dom';

import IconLoader from 'app/components/IconLoader';
import ErrorPage from 'core/containers/ErrorPage';
import { useGetPermission } from 'core/hooks/usePermission';

import { ContentWrap, FullscreenRouteWrap } from './styled';

interface Props extends RouteProps {
  component: ComponentType<RouteComponentProps<any>>;
  permission?: string;
}

const FullscreenRoute: FC<Props> = ({ component: Component, permission, ...rest }) => {
  const getPermission = useGetPermission();
  const { t } = useTranslation();

  return !getPermission(permission || '') ? (
    <ErrorPage title={t('Access not permitted.')} />
  ) : (
    <Route
      {...rest}
      render={(routeProps) => (
        <>
          <FullscreenRouteWrap>
            <Suspense fallback={<IconLoader />}>
              <ContentWrap>
                <Component {...routeProps} />
              </ContentWrap>
            </Suspense>
          </FullscreenRouteWrap>
        </>
      )}
    />
  );
};

export default FullscreenRoute;
