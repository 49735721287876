import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';
import { FC } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { Switch } from 'react-router-dom';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import { Store } from 'redux';
import { Persistor } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import 'react-toastify/dist/ReactToastify.css';

import { allocationRoutes } from 'allocations';
import useRenderRoutes from 'app/hooks/useRenderRoutes';
import FocusWorkaround from 'core/components/FocusWorkaround';
import IconButton from 'core/components/IconButton';
import ErrorPage from 'core/containers/ErrorPage';
import FullscreenRoute from 'core/containers/FullscreenRoute';
import PermissionsPage from 'core/containers/PermissionsPage';
import SidebarRoute from 'core/containers/SidebarRoute';
import safeLazy from 'core/functions/safeLazy';
import ApiCallToasts from 'core/hooks/useApiCall/ApiCallToasts';
import { financeRoutes } from 'finances';
import { forecastRoutes } from 'forecasts';
import { listRoutes } from 'lists';
import { personnelRoutes } from 'personnel';
import { projectRoutes } from 'projects';
import { salesRoutes } from 'sales';
import { timesheetRoutes } from 'timesheets';

const Home = safeLazy(() => import('core/containers/HomePage'));
const Login = safeLazy(() => import('core/containers/LoginPage'));
const ForgotPassword = safeLazy(() => import('core/containers/ForgotPasswordPage'));
const ResetPassword = safeLazy(() => import('core/containers/ResetPasswordPage'));
const ActivateAccount = safeLazy(() => import('core/containers/ActivateAccountPage'));
const UnlockAccount = safeLazy(() => import('core/containers/UnlockAccountPage'));

const AccountPage = safeLazy(() => import('personnel/pages/AccountPage'));

const FilesPage = safeLazy(() => import('core/containers/FilesPage'));

interface Props {
  store: Store;
  history: History;
  persistor: Persistor;
}

const App: FC<Props> = ({ store, history, persistor }) => {
  const { t } = useTranslation();

  /**
   *  Used on `SidebarRoute` components.
   *  The reason is that we want to render multiple `SidebarRoute` components that have the same
   *  children wrapped around `Router`, but different props.
   *  If we would use a unique key on each `SidebarRoute`/`Route`, React Router would re-create
   *  (i.e. unmount and mount) the same children components on every single switch.
   *  Hence, this variable is used to tell React that the component didn't unmount when React Router switches routes
   *  and that only the props changed.
   *  Normally, we would receive 'Encountered two children with the same key' error message
   *  from React, but in this case, this will work because React Router never renders two routes at the same time.
   *  Hence, we can still dynamically map routes data to the `Route` components.
   *
   * TODO: Remove this once we upgrade to react-router-dom v6
   */
  const sidebarRouteKey = 'sidebarRouteKey';

  const sales = useRenderRoutes(salesRoutes, sidebarRouteKey);
  const project = useRenderRoutes(projectRoutes, sidebarRouteKey);
  const personnel = useRenderRoutes(personnelRoutes, sidebarRouteKey);
  const timesheet = useRenderRoutes(timesheetRoutes, sidebarRouteKey);
  const allocation = useRenderRoutes(allocationRoutes, sidebarRouteKey);
  const forecast = useRenderRoutes(forecastRoutes, sidebarRouteKey);
  const finance = useRenderRoutes(financeRoutes, sidebarRouteKey);
  const list = useRenderRoutes(listRoutes, sidebarRouteKey);

  return (
    <Provider store={store}>
      <HelmetProvider>
        <FocusWorkaround />
        <ToastContainer
          position={toast.POSITION.BOTTOM_RIGHT}
          className="material-redesign"
          closeButton={({ closeToast }) => <IconButton onClick={closeToast} icon="close" />}
          transition={Zoom}
          hideProgressBar
          newestOnTop
          icon={false}
        />
        <PersistGate persistor={persistor}>
          <ApiCallToasts />
          <ConnectedRouter history={history}>
            <Switch>
              <FullscreenRoute exact path="/forgot-password" component={ForgotPassword} />
              <FullscreenRoute exact path="/reset-password/:code" component={ResetPassword} />
              <FullscreenRoute exact path="/activate-account/:code" component={ActivateAccount} />
              <FullscreenRoute exact path="/unlock-account/:code" component={UnlockAccount} />
              <FullscreenRoute path="/login" component={Login} />
              <SidebarRoute
                exact
                path="/"
                permission="dashboard:view"
                component={Home}
                key={sidebarRouteKey}
              />
              <SidebarRoute
                path="/account"
                permission="profile:find"
                component={AccountPage}
                key={sidebarRouteKey}
              />

              <SidebarRoute
                component={FilesPage}
                permission="files:find"
                path="/attachments"
                clientOptions={['enableAttachments']}
                key={sidebarRouteKey}
                exact
              />

              <SidebarRoute
                component={PermissionsPage}
                permission="admin/permissions:find"
                path="/permissions"
                key={sidebarRouteKey}
                exact
              />

              {personnel}
              {sales}
              {project}
              {timesheet}
              {allocation}
              {forecast}
              {finance}
              {list}

              <FullscreenRoute
                component={() => (
                  <ErrorPage
                    title={t('The page you are looking for wasn’t found or doesn’t exist.')}
                  />
                )}
              />
            </Switch>
          </ConnectedRouter>
        </PersistGate>
      </HelmetProvider>
    </Provider>
  );
};

export default App;
