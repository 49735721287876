import { allocationsUrls } from 'allocations';
import { entities } from 'app/entity';
import { forecastsUrls } from 'forecasts';

import getNavGroups from './getNavGroups';
import type { NavGroup, NavSubItem } from './getNavGroups';

export { getNavGroups };
export type { NavGroup, NavSubItem };

export type FormPageURLs = {
  readonly create?: string | ((id: number) => string);
  readonly clone?: (id: number) => string;
  readonly detail: (id: number) => string;
  readonly list: string;
};

export const defaultSearchUrl = entities.project.urls().list;

export const urls = {
  currentClient: '/client',
  account: '/account',
  accountForecasts: '/account/forecasts',
  fileTypes: '/files/types',
  logout: '/logout',
  login: '/login',
  dashboard: '/',
  attachment: '/attachments',
  permissions: '/permissions',
  ...allocationsUrls,
  ...forecastsUrls,
} as const;

export const publicPathNames = [
  '/login',
  '/forgot-password',
  '/reset-password',
  '/activate-account',
  '/unlock-account',
];

export const customActiveUrls = [];
