/* eslint-disable @typescript-eslint/no-unused-vars */
import { definition, EntityMeta, getStandardApi, T } from 'core/module';

import { Forecasts, ProjectForecastsGrid } from './models/Forecast';

export const forecastEntities = {
  forecast: {
    ...definition<ProjectForecastsGrid, Forecasts>('forecasts', {
      name: (t: T) => t('Forecasts'),
      listName: (t: T) => t('Forecasts'),
    }),
    api: () => ({
      ...getStandardApi('forecasts'),
      forecasts: '/forecasts',
      forecastProjectHistory: (id: string | number | undefined) =>
        `/forecasts/project/${id}/history`,
      history: '/forecasts/history',
      projectForecasts: (id: string | number | undefined) => `/forecasts/project/${id}`,
    }),
  },
} as const;

// REQUIRED !!!
// Ts-ignored because forecasts do not implements standard api
// @ts-ignore
const verifyEntities: Record<keyof typeof forecastEntities, EntityMeta> = forecastEntities;

export type K = keyof typeof forecastEntities;
