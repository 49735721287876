import { useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

// Following advice from here: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
const useIpadHeightFix = () => {
  const [debouncedResizeWindow] = useDebouncedCallback(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, 500);

  useEffect(() => {
    debouncedResizeWindow();
    window.addEventListener('resize', debouncedResizeWindow);

    return () => {
      window.removeEventListener('resize', debouncedResizeWindow);
    };
  }, [debouncedResizeWindow]);

  return null;
};

export default useIpadHeightFix;
