import { toast } from 'react-toastify';
import { put, take, race } from 'redux-saga/effects';

import { apiCall, RestorePasswordAction, TYPE_RESTORE_PASSWORD } from 'core/actions';
import { t } from 'core/i18n';
import {
  InviteUsersAction,
  TYPE_INVITE_USERS,
  TYPE_RESEND_WELCOME_EMAIL,
  ResendWelcomeEmailAction,
} from 'personnel/actions';

export function* restoreUserPasswordSaga(action: RestorePasswordAction) {
  yield put({ type: TYPE_RESTORE_PASSWORD, payload: action.payload });

  const { success } = yield race({
    success: take(`${TYPE_RESTORE_PASSWORD}_SUCCESS`),
    error: take(`${TYPE_RESTORE_PASSWORD}_ERROR`),
  });

  if (success) {
    toast.success(
      t('Reset password link was sent to {{email}}', {
        email: action.payload.email,
      })
    );
  }
}

export function* inviteUsersSaga(action: InviteUsersAction) {
  const {
    successCallback,
    data: { skipInvitationEmail, ...data },
  } = action.payload;
  yield put(
    apiCall(
      action.type,
      'POST',
      skipInvitationEmail ? '/users?skipInvitationEmail=true' : '/users',
      data
    )
  );

  const { success } = yield race({
    success: take(`${TYPE_INVITE_USERS}_SUCCESS`),
    error: take(`${TYPE_INVITE_USERS}_ERROR`),
  });

  if (success) {
    toast.success(
      skipInvitationEmail ? t('New Account(s) has been created') : t('Invitation has been sent')
    );

    successCallback && successCallback();
  }
}

export function* resendWelcomeEmailSaga(action: ResendWelcomeEmailAction) {
  const { usersId } = action.payload;

  yield put(apiCall(TYPE_RESEND_WELCOME_EMAIL, 'POST', `/users/${usersId}/resend-email`));

  const { success } = yield race({
    success: take(`${TYPE_RESEND_WELCOME_EMAIL}_SUCCESS`),
    error: take(`${TYPE_RESEND_WELCOME_EMAIL}_ERROR`),
  });

  if (success) {
    toast.success(t('The welcome email is being resent'));
  }
}
