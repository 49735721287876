import { setUseWhatChange } from '@simbathesailor/use-what-changed';
import { createRoot } from 'react-dom/client';

import 'app/assets/fonts.scss';
import 'app/assets/app.scss';
import 'app/assets/icomoon/style.css';

import App from 'app/components/App';
import prepareStore from 'app/store/prepareStore';
import ErrorBoundary from 'core/components/ErrorBoundary';
import extendYup from 'core/extendYup';
import getEnv from 'core/functions/getEnv';
import history from 'core/history';
import 'core/polyfills';
import i18nInit from 'core/i18n';

const logRocketKey = getEnv('LOGROCKET_ID');

// Initialize log-rocket
if (process.env.NODE_ENV === 'production' && logRocketKey) {
  import('logrocket').then((m) => {
    m?.default?.init(logRocketKey, {
      release: getEnv('VERSION'),
    });
  });
}

// Helper for debugging of dependencies https://github.com/simbathesailor/use-what-changed
if (process.env.NODE_ENV === 'development') setUseWhatChange(true);

const { store, persistor } = prepareStore(history);

i18nInit(store);
extendYup();

const root = createRoot(document.getElementById('root')!);
root.render(
  <ErrorBoundary fullScreen={true} includeReload={true}>
    <App store={store} history={history} persistor={persistor} />
  </ErrorBoundary>
);
