import { takeLatest, takeLeading } from 'redux-saga/effects';

import { TYPE_EDIT_PROFILE } from 'personnel/actions/profile';

import {
  TYPE_RESTORE_USER_PASSWORD,
  TYPE_INVITE_USERS,
  TYPE_EDIT_CLIENT,
  TYPE_RESEND_WELCOME_EMAIL,
} from '../actions';

import * as clientSaga from './client';
import { editProfileSaga } from './editProfile';
import * as usersSaga from './users';

export default function* root() {
  yield takeLatest(TYPE_RESTORE_USER_PASSWORD, usersSaga.restoreUserPasswordSaga);
  yield takeLeading(TYPE_INVITE_USERS, usersSaga.inviteUsersSaga);
  yield takeLatest(TYPE_RESEND_WELCOME_EMAIL, usersSaga.resendWelcomeEmailSaga);
  yield takeLatest(TYPE_EDIT_CLIENT, clientSaga.editClientSaga);
  yield takeLatest(TYPE_EDIT_PROFILE, editProfileSaga);
}
