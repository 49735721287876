import { RouteDef } from 'app/hooks/useRenderRoutes';
import safeLazy from 'core/functions/safeLazy';

export const allocationsUrls = { allocations: '/allocations' };

export const allocationRoutes: RouteDef[] = [
  {
    path: allocationsUrls.allocations,
    component: safeLazy(() => import('./pages/AllocationsPage')),
    layout: 'side',
  },
];
