import { ColDef } from 'ag-grid-community';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import theme from 'app/theme';
import { useResources } from 'core/hooks/useResource';
import { Role } from 'core/models/Role';

import { camelCaseToSpaces, formatParams, formatPermissionName } from './helpers';
import { ExtendedPermission, PermissionGroup } from './useGroupPermissions';

const useColumnDefs = () => {
  const { t } = useTranslation();

  const { data: roles } = useResources<Role>('roles');

  return useMemo(
    (): ColDef<ExtendedPermission | PermissionGroup>[] => [
      {
        headerName: t('Name'),
        field: 'controller',
        valueGetter: ({ data }) => {
          if (!data) return undefined;

          return 'title' in data
            ? camelCaseToSpaces(data.title)
            : formatPermissionName(data.controller, data.action);
        },
        colSpan: ({ data }) => (data && 'group' in data ? (roles || []).length + 2 : 1),
        cellStyle: ({ data }) => {
          if (data && 'group' in data) {
            return {
              backgroundColor: theme.color.gray100,
              fontWeight: 'bolder',
            };
          }
          return null;
        },
        minWidth: 220,
      },
      ...(roles || []).map((role) => {
        return {
          headerName: role.name,
          field: 'rolesId',
          minWidth: 100,
          width: 150,
          cellRenderer: 'iconCellRenderer',
          suppressSizeToFit: true,
          cellRendererParams: {
            alignRight: false,
            icons: [
              {
                name: 'done',
                color: theme.color.success,
                visible: (data: any) => 'rolesId' in data && data.rolesId.includes(`${role.id}`),
              },
              {
                name: 'clear',
                color: theme.color.gray200,
                visible: (data: any) => !('rolesId' in data && data.rolesId.includes(`${role.id}`)),
              },
            ],
          },
        };
      }),
      {
        headerName: t('Parameters'),
        field: 'params',
        minWidth: 800,
        valueGetter: ({ data }) => {
          if (!data) return undefined;
          return formatParams(('params' in data && data.params) || {});
        },
        tooltipValueGetter: ({ data }) => {
          return data && 'controller' in data ? `${data.controller}:${data.action}` : '';
        },
      },
    ],
    [t, roles]
  );
};

export default useColumnDefs;
