export const TYPE_EDIT_BUSINESS_PLAN_SETTINGS = 'EDIT_BUSINESS_PLAN_SETTINGS';
export const editBusinessPlanSettings = (
  endpoint: string,
  data: any,
  prevData: any,
  successCallback?: () => void
) =>
  ({
    type: TYPE_EDIT_BUSINESS_PLAN_SETTINGS,
    payload: {
      req: {
        endpoint,
        data,
      },
      data,
      prevData,
      successCallback,
    },
  }) as const;
export type BusinessPlanEditSettingsAction = ReturnType<typeof editBusinessPlanSettings>;

export type BusinessPlanActions = BusinessPlanEditSettingsAction;
