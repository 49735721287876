import { RouteDef } from 'app/hooks/useRenderRoutes';
import safeLazy from 'core/functions/safeLazy';

import { listEntities } from './module';

export const listRoutes: RouteDef[] = [
  // CostClusters

  {
    path: listEntities.costCluster.urls().list,
    component: safeLazy(() => import('./pages/CostClustersPage')),
    layout: 'side',
  },
  {
    path: listEntities.costCluster.urls().detail(':id'),
    component: safeLazy(() => import('./pages/CostClusterDetailPage')),
    layout: 'side',
    mode: 'edit',
    exact: false,
  },
  {
    path: listEntities.costCluster.urls().create,
    component: safeLazy(() => import('./pages/CostClusterDetailPage')),
    layout: 'side',
    mode: 'create',
    exact: false,
  },
  {
    path: listEntities.costCluster.urls().clone(':id'),
    component: safeLazy(() => import('./pages/CostClusterDetailPage')),
    layout: 'side',
    mode: 'clone',
    exact: false,
  },

  // CostCategories
  {
    path: listEntities.costCategory.urls().list,
    component: safeLazy(() => import('./pages/CostCategoriesPage')),
    layout: 'side',
  },
  {
    path: listEntities.costCategory.urls().detail(':id'),
    component: safeLazy(() => import('./pages/CostCategoryDetailPage')),
    layout: 'side',
    mode: 'edit',
    exact: false,
  },
  {
    path: listEntities.costCategory.urls().create,
    component: safeLazy(() => import('./pages/CostCategoryDetailPage')),
    layout: 'side',
    mode: 'create',
    exact: false,
  },
  {
    path: listEntities.costCategory.urls().clone(':id'),
    component: safeLazy(() => import('./pages/CostCategoryDetailPage')),
    layout: 'side',
    mode: 'clone',
    exact: false,
  },
];
