import { ReactNode, ReactText } from 'react';
import { toast, TypeOptions } from 'react-toastify';

import { ItemLoadingIcon } from 'core/components/Notifications/styled';
import { t } from 'core/i18n';

const pendingToasts: Record<string, () => void | undefined | string | ReactText> = {};

export type ToastType = 'info' | 'success' | 'warning' | 'error' | 'loading';

const PENDING_TIMEOUT = 1000;

/**
 * Display Notification Toast based on input settings combination
 */
function displayApiToast(key: string, type?: ToastType, message?: ReactNode) {
  const prevToastId = pendingToasts[key] ? (pendingToasts[key]() as string) : undefined;

  // Ignore empty messages
  if (!type || !message) {
    return;
  }

  // display React-toastify common notification
  if (type && type !== 'loading') {
    if (!prevToastId) {
      const id = toast[type](message);
      pendingToasts[key] = () => id;
    } else {
      toast.update(prevToastId, {
        type: type as TypeOptions,
        render: message,
        bodyClassName: '',
        autoClose: 5000,
        closeOnClick: true,
        closeButton: true,
      });
    }
    return;
  }

  // Pending Indicator
  const timer = setTimeout(() => {
    const id = toast(
      <>
        <ItemLoadingIcon color="gray300" icon="cloud_upload" />
        <span>{message ? message : `${t('Processing...')}`}</span>
      </>,
      {
        bodyClassName: 'loadingToast',
        autoClose: false,
        closeButton: false,
        closeOnClick: false,
        draggable: false,
      }
    );
    pendingToasts[key] = () => id;
  }, PENDING_TIMEOUT);

  pendingToasts[key] = () => {
    clearTimeout(timer);
  };
}

/**
 * Close Toast by ID
 */
export function closeToast(key: string) {
  const id = pendingToasts[key] && pendingToasts[key]();
  id && toast.dismiss(id);
}

/**
 * Close All Toasts
 */
export function closeToasts() {
  Object.keys(pendingToasts).forEach((key) => {
    const id = pendingToasts[key] && pendingToasts[key]();
    id && toast.dismiss(id);
  });
}

export default displayApiToast;
