import de from './de.json';
import en from './en.json';

export const DEFAULT_LANGUAGE = 'en';
export const DEFAULT_LOCALE = 'en-GB';

// TODO: think about dynamic import
export const resources = {
  de,
  en,
} as const;

export type SupportedLocale = keyof typeof resources;
