import * as Sentry from '@sentry/browser';
import { useCallback, useMemo } from 'react';

import Event, { EventType } from 'app/models/Event';
import { Notification } from 'core/components/Notifications/RenderNotification';
import useBulkDownload from 'core/components/Notifications/templates/useBulkDownload';
import useCreateClient from 'core/components/Notifications/templates/useCreateClient';

/**
 * Decorate list of events for rendering
 */
const useNotificationsReducer = () => {
  const getCreateClientNotification = useCreateClient();
  const getBulkDownloadNotification = useBulkDownload();

  const map = useMemo<{ [key in EventType]: (e: Event) => Notification }>(
    () => ({
      [EventType.CREATE_CLIENT]: getCreateClientNotification,
      [EventType.BULK_DOWNLOAD]: getBulkDownloadNotification,
    }),
    [getCreateClientNotification, getBulkDownloadNotification]
  );

  return useCallback(
    ([inP, oE]: Notification[][], e: Event): Notification[][] => {
      if (e.type in map) {
        const n = map[e.type](e);
        (n.inProgress ? inP : oE).push(n);
      } else {
        // eslint-disable-next-line no-console
        console.error(`Unknown event type found ${e.type} (created at ${e.createdAt})`);
        Sentry.captureException(
          new Error(`Unknown event type found ${e.type} (created at ${e.createdAt})`)
        );
      }

      return [inP, oE];
    },
    [map]
  );
};

export default useNotificationsReducer;
