import { ComponentType, FC, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';

import { urls } from 'app/urls';
import LoadingPage from 'core/components/LoadingPage';
import Media from 'core/components/Media';
import ErrorPage from 'core/containers/ErrorPage';
import Sidebar from 'core/containers/Sidebar';
import useAppSelector from 'core/hooks/useAppSelector';
import { useBoolClientOptions } from 'core/hooks/useClientOption';
import { ModalProvider } from 'core/hooks/useModal';
import usePermission from 'core/hooks/usePermission';
import { RolesId } from 'core/models/CoreUser';

import { DetailMode } from '../FormDetailPage';

import { SidebarRouteWrap } from './styled';
import useIpadHeightFix from './useIpadHeightFix';

interface Props extends RouteProps {
  component: ComponentType<RouteComponentProps<any>> | ComponentType<any>;
  permission?: string;
  scopePermission?: string;
  mode?: DetailMode;
  clientOptions?: string[];
  /**
   * Optional roles that will be the only one allowed to this page
   */
  restrictedToRoles?: RolesId[];
}

const SidebarRoute: FC<Props> = ({
  component: Component,
  permission,
  scopePermission,
  path,
  clientOptions,
  restrictedToRoles,
  ...rest
}) => {
  useIpadHeightFix();

  const { isLoggedIn, user } = useAppSelector(({ core: { isLoggedIn, user } }) => ({
    isLoggedIn,
    user,
  }));

  const { t } = useTranslation();
  const hasParentScopePermission = usePermission(
    `${permission || ''}[scope:${scopePermission || ''}]`
  );
  const hasPermission = usePermission(permission || '');
  const isHomepage = path === '/';
  const clientOptionEnabled = useBoolClientOptions(clientOptions);
  const rolesId = useAppSelector(({ core }) => core.user?.rolesId);
  const hasRestrictedRole = !rolesId || !restrictedToRoles || restrictedToRoles.includes(rolesId);

  if (!isLoggedIn) {
    return <Redirect to={`${urls.login}${window.location.search}`} />;
  }

  // we have persisted flag only and profile its loaded on init
  if (isLoggedIn && !user) {
    return <LoadingPage displayHeader={false} />;
  }

  if (isHomepage && !hasPermission && !hasParentScopePermission) {
    return <Redirect to={urls.account} />;
  }

  if ((!hasPermission && !hasParentScopePermission) || !clientOptionEnabled || !hasRestrictedRole) {
    return <ErrorPage title={t('Access not permitted.')} />;
  }

  return (
    <Route
      {...rest}
      render={(routeProps) => (
        <SidebarRouteWrap>
          <Media query="desktop">
            <Sidebar />
          </Media>
          <Suspense fallback={<LoadingPage />}>
            <ModalProvider>
              <Component {...routeProps} {...rest} />
            </ModalProvider>
          </Suspense>
        </SidebarRouteWrap>
      )}
    />
  );
};

export default SidebarRoute;
