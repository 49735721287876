import { ComponentType, lazy } from 'react';

import IconLoader from 'app/components/IconLoader';

const safeLazy = (
  factory: () => Promise<{
    default: ComponentType<any>;
  }>
) => {
  const safeFactory = () => {
    return factory().catch((e: Error & { code: string }) => {
      // JS error is defined by the name, CSS by the code
      const isChunkError = e.name === 'ChunkLoadError' || e.code === 'CSS_CHUNK_LOAD_FAILED';
      if (isChunkError) {
        // eslint-disable-next-line no-console
        console.warn('Catched chunk error', e);

        // We postpone the reload if we just reloaded - we don't want to be in the endless loop of constant reloading
        const currentUnixTimestamp = Math.round(Date.now() / 1000);
        const lastReload = localStorage.getItem('lastChunkErrorReload');
        const timeout = +(lastReload || 0) + 5 > currentUnixTimestamp ? 5000 : 0;

        // If this doesn't reload index.html correctly, we can introduce dummy query string
        setTimeout(() => window.location.reload(), timeout);

        // Mark when was the last chunk error reload done in unix time stamp - seconds
        localStorage.setItem('lastChunkErrorReload', currentUnixTimestamp.toString());

        return Promise.resolve({ default: IconLoader });
      }

      return Promise.reject(e);
    });
  };

  return lazy(safeFactory);
};

export default safeLazy;
