import { FC } from 'react';

import { useApiCallToasts } from './useApiCallToasts';

/**
 * Top level Watcher Component
 */
const ApiCallToasts: FC = () => {
  useApiCallToasts();

  return null;
};

export default ApiCallToasts;
