import mapValues from 'lodash/mapValues';
import { lighten, darken } from 'polished';

const breakpoints = {
  mobile: 25,
  tablet: 48,
  desktop: 75,
};

const primary = '#12BC96';
const secondary = '#003858';
const success = '#28a745';
const success50 = lighten(0.4, success);
const error = '#dc3545';
const error50 = lighten(0.35, error);
const warning = '#ffc107';
const info = '#17a2b8';
const primary700 = darken(0.1, primary);
const primary50 = lighten(0.5, primary);
const primary5 = lighten(0.95, primary);
const secondary800 = lighten(0.05, secondary);
const secondary700 = lighten(0.05, secondary800);
const secondary600 = lighten(0.05, secondary700);
const secondary500 = lighten(0.05, secondary600);
const secondary400 = lighten(0.05, secondary500);
const secondary300 = lighten(0.05, secondary400);
const secondaryLighter = 'rgba(0, 128, 240, 0.3)';
const gray = '#f5f5f5';
const darkGray = '#949494';
const gray50 = '#fbfbff';
const gray100 = '#e8e8e8';
const gray200 = darken(0.05, gray100);
const gray300 = '#9e9e9e';
const gray400 = 'rgba(0, 0, 0, 0.6)';
const gray500 = '#616161';
const white = '#fff';
const white100 = 'rgba(255, 255, 255, 0.1)';
const black = '#000000';
const baseFontColor = '#333';
const darkBlue = '#073350';
const sidebarBackground = `linear-gradient(180deg, #19639F 0%, #159B93 100%)`;

const nestedSidebarBackground = `linear-gradient(180deg, #103F64 0%, #10867F 100%)`;
const nestedSidebarCardBackgroundActive = '#ffffff59';
const nestedSidebarCardBackground = '#FFFFFF26';

// TODO: Refactor this mess
const theme = {
  color: {
    white,
    white100,
    black,
    textSecondary: 'rgba(0, 0, 0, 0.54)',
    primary,
    darkBlue,
    primary700,
    primary50,
    primary5,
    secondary,
    secondary800,
    secondary700,
    secondary600,
    secondary500,
    secondary400,
    secondary300,
    secondaryLighter,
    gray,
    gray50,
    gray100,
    gray200,
    gray300,
    gray400,
    gray500,
    darkGray,
    success,
    success50,
    successBackground: lighten(0.5, success),
    warning,
    warningBackground: lighten(0.45, warning),
    error,
    error50,
    errorBackground: lighten(0.4, error),
    info,
    infoBackground: lighten(0.4, info),
    baseFontColor,
    status: {
      white,
      blue: '#0080f0',
      green: '#4caf50',
      orange: '#ff9800',
      red: '#f44336',
      cyan: '#00bcd4',
      gray: '#9e9e9e',
      purple: '#9c27b0',
    },
    statusIcons: {
      blue: 'arrow_up_circle',
      gray: 'empty_circle',
      green: 'checkmark_circle',
      orange: 'empty_circle',
      red: 'cancel_circle',
      purple: 'vertical_line_circle',
      cyan: 'arrow_up_circle',
      white: '',
    } as const,
  },
  breakpoints,
  mq: mapValues(breakpoints, (x) => `@media (min-width: ${x}rem)`),
  drawerWidth: '15.625rem',
  nestedDrawerWidth: '12.5rem',
  drawerColapseWidth: '3.5rem',
  popoverShadow: `0px 5px 5px -4px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
  0px 3px 14px 2px rgba(0, 0, 0, 0.12)`,
  sidebarActiveBackground: 'rgba(0,0,0,0.25)',
  sidebarBackground,
  sidebarLogoHeight: '1.5rem',
  nestedSidebarBackground,
  nestedSidebarCardBackground,
  nestedSidebarCardBackgroundActive,
};

type Theme = typeof theme;
export type ThemeColor = keyof (typeof theme)['color'];
export type StatusIcon = keyof (typeof theme)['color']['statusIcons'];
export type StatusIconValue = (typeof theme)['color']['statusIcons'][StatusIcon];
export type StatusColor = keyof Theme['color']['status'];

export default theme;
