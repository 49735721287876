import { entities } from 'app/entity';
import { RouteDef } from 'app/hooks/useRenderRoutes';
import safeLazy from 'core/functions/safeLazy';

import { salesEntities } from './module';

export const salesRoutes: RouteDef[] = [
  // Companies
  {
    path: salesEntities.company.urls().list,
    component: safeLazy(() => import('./pages/CompanyListPage')),
    layout: 'side',
  },
  {
    path: salesEntities.company.urls().detail(':id'),
    component: safeLazy(() => import('./pages/CompanyDetailPage')),
    layout: 'side',
    mode: 'edit',
    exact: false,
  },
  {
    path: salesEntities.company.urls().create,
    component: safeLazy(() => import('./pages/CompanyDetailPage')),
    layout: 'side',
    mode: 'create',
    exact: false,
  },
  {
    path: salesEntities.company.urls().clone(':id'),
    component: safeLazy(() => import('./pages/CompanyDetailPage')),
    layout: 'side',
    mode: 'clone',
    exact: false,
  },

  // Contacts
  {
    path: entities.contact.urls().list,
    component: safeLazy(() => import('./pages/ContactListPage')),
    layout: 'side',
  },
  {
    path: entities.contact.urls().detail(':id'),
    component: safeLazy(() => import('./pages/ContactDetailPage')),
    layout: 'side',
    mode: 'edit',
    exact: false,
  },
  {
    path: entities.contact.urls().create,
    component: safeLazy(() => import('./pages/ContactDetailPage')),
    layout: 'side',
    mode: 'create',
    exact: false,
  },
  {
    path: salesEntities.contact.urls().clone(':id'),
    component: safeLazy(() => import('./pages/ContactDetailPage')),
    layout: 'side',
    mode: 'clone',
    exact: false,
  },
  {
    path: salesEntities.contact.urls().leadManagement,
    component: safeLazy(() => import('./pages/LeadManagementPage')),
    layout: 'side',
  },
  {
    path: entities.project.urls().pipeline,
    component: safeLazy(() => import('./pages/PipelinePage')),
    layout: 'side',
  },
];
