import { definition, EntityMeta, getStandardApi, getStandardUrls } from 'core/module';
import { projectsEntities } from 'projects/module';

import { CompanyCreate, CompanyDetail, CompanyGrid } from './models/Company';
import { ContactCreate, ContactDetail, ContactGrid } from './models/Contact';

export const salesEntities = {
  company: {
    ...definition<CompanyGrid, CompanyDetail, CompanyCreate>('companies', {
      name: (t) => t('Company'),
      listName: (t) => t('Companies'),
    }),
    urls: () => ({
      ...getStandardUrls('companies'),
      createContact: (companiesId: number) =>
        `${salesEntities.contact.urls().create}/detail?companiesId=${companiesId}`,
      createProject: (companiesId: number) =>
        `${projectsEntities.project.urls().create}/detail?companiesId=${companiesId}`,
    }),
    api: () => ({
      ...getStandardApi('companies'),
      image: (id: number) => `/companies/${id}/image`,
    }),
  },

  contact: {
    ...definition<ContactGrid, ContactDetail, ContactCreate>('contacts', {
      name: (t) => t('Contact'),
      listName: (t) => t('Contacts'),
    }),
    urls: () => ({
      ...getStandardUrls('contacts'),
      leadManagement: '/lead-management',
    }),
    api: () => ({
      ...getStandardApi('contacts'),
      image: (id: number) => `/contacts/${id}/image`,
      leadManagement: '/contacts/pipeline',
    }),
  },
} as const;

// REQUIRED !!!
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const verifyEntities: Record<keyof typeof salesEntities, EntityMeta> = salesEntities;

export type K = keyof typeof salesEntities;
